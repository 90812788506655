<template>
    <div class="game-load-block main-bg-light row mx-0 py-5">
        <div class="col-12 col-lg-11 mx-auto" :class="[!isWheel ? 'mt-2 mt-md-5 mt-lg-0' : 'mt-0 mt-5']">
            <div class="row mx-0 w-100" v-if="!isWheel">
                <div class="col-12 col-xl-12  mx-auto px-0">
                    <a href="#"
                       class="btn enableFullScreen d-none d-md-inline border-0 p-0"
                       id="fullScreenBtn"
                       @click.prevent="screenToggle">
                        <i class="fa fa-arrows-alt text-white" v-if="!isFullScreenMode"></i>
                        <i class="fa fa-close text-white" v-else></i>
                    </a>
                    <a href="#" @click.prevent="closeUrl()"
                       class="btn close-btn position-absolute text-white p-0">
                        <i class="fa fa-close text-white"></i>
                    </a>
                </div>
            </div>

            <div class="row w-100 mx-0 pb-0 pb-lg-5 mt-0 mt-md-0">
                <div class="col-12 col-lg-12 col-xl-9 px-0 game-cross-block">
                    <div class="row w-100" v-if="!loaded">
                        <Alert :message="response" class="game-load-alert position-absolute text-center"></Alert>
                    </div>
                    <div class="px-0 game-response-container text-center" id="game-response-container">
                        <a href="#" @click.prevent="closeUrl()"
                           class="btn text-white mx-2 mr-0 float-right border-0" id="close-btn-full">
                            <i class="fa fa-close text-white"></i>
                        </a>

                        <a href="#" class="btn border-0 px-0 float-right mx-2" id="minimize" @click.prevent="screenToggle" v-if="!isFullScreenMode">
                            <i class="fa fa-window-minimize text-white"></i>
                        </a>

                        <iframe class="gameLaunchIframe"
                                :src="gameLaunchUrl()"
                                allowfullscreen
                                frameborder="0"
                                scrolling="no"
                                marginheight="0">
                        </iframe>
                    </div>
                </div>

                <div class="col-3 col-xl-3 px-0 d-none d-xl-block" id="gameInfoArea" v-if="!isWheel">
                    <b-tabs class="game-page-tab game-info-area text-white py-0 px-0">
                        <b-tab class="col-12" active>
                            <template slot="title" class="mt-2 mx-0">
                                <div class="tab-title py-3 col-12 text-center">
                                    <img :src="gamesIcon" class="tab-icon mr-2" alt="slote games" height="25px">
                                    <span class="fs-14">Игры</span>
                                </div>
                            </template>
                            <div class="game-tab-body w-100">
                                <div class="row mx-0 border-0 px-3 text-white tab-title-area">
                                    <span class="game-rateing-title py-2"> Рейтинг популярных игр: </span>
                                </div>

                                <div class="games">
                                    <div class="row mx-0 game cursor-pointer py-2" v-for="(game, index) in games"
                                         :key="'game_'+index"
                                         v-bind:class="[index % 2 ? 'light-bg' : '']">

                                        <div class="col-1 px-0 index-area d-table h-100">
                                            <div class="d-table-cell vertical-middle text-center">
                                                <span class="font-weight-bold ">{{index + 1}}</span>
                                            </div>
                                        </div>

                                        <div class="col-11 px-0 h-100">
                                            <div class="row h-100 mx-0">
                                                <div class="col-5 h-100 px-0">
                                                    <img :src="$config.baseUrl + '/' + game.picture" alt="segaslot game"
                                                         class="game-img m-auto mh-100 w-100">
                                                </div>
                                                <div class="col-7 d-table h-100">
                                                    <div class="d-table-cell vertical-middle">
                                                        <span class="game-title font-weight-bold fs-14 text-orange"> {{game.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <SuperomaticSelecter v-on:superoNominationSelected="superoNominationSelected" :game="game"
                             :showModal="showSuperomaticModal" ref="superomaticSelecter"></SuperomaticSelecter>
    </div>
</template>

<script>
    /*eslint no-debugger: "error"*/
    import {scroller} from 'vue-scrollto/src/scrollTo'
    import Alert from '@/components/Alert'
    import Config from '@/config'
    import SuperomaticSelecter from "./SuperomaticSelecter"

    const secondScrollTo = scroller()

    export default {
        name: 'TheGame',
        props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'isLive'],
        data() {
            return {
                isLandscape: false,
                response: {status: 'loading'},
                gamesIcon: require('@/../public/images/icons/games/game.png'),
                isFullScreenMode: false,
                superomaticNomination: null,
                loaded: false,
                showSuperomaticModal: false
            }
        },
        components: {
            Alert,
            SuperomaticSelecter
        },
        watch: {
            isLandscape() {
                this.isLandscape = screen.height < screen.width
            },
            restoring() {
                if (this.restoring) {
                    this.$bvModal.hide('HasAliveGame')
                }
            }
        },
        computed: {
            isWheel() {
                return typeof this.provider !== 'undefined' && this.provider.toLowerCase() === 'wheeloffortune'
            },
            userData() {
                return this.$store.getters['userData']
            },
            games() {
                let allGames = this.$store.getters['games']
                let keys = Object.keys(allGames)
                let rndKey = keys.length * Math.random() << 0
                let topGames = []

                if (typeof allGames[keys[rndKey]] !== 'undefined') {
                    topGames = allGames[keys[rndKey]].games.slice(0, 10)
                }
                return topGames
            },
            gameCloseUrl() {
                return Config.baseUrl + '/Api/CloseGame/' + this.provider
            }
        },
        methods: {
            gameLaunchUrl() {
                if (!this.provider) {
                    return ''
                }

                let gameLaunchUrl = [
                    Config.baseUrl,
                    '/Api/LoadGame/',
                    this.provider,
                    '/',
                    this.gameId ? this.gameId : ''
                ].join('')

                if (typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'superomatic') {
                    return gameLaunchUrl
                } else {
                    if (!this.superomaticNomination && !this.restoring) {
                        this.showSuperomaticModal = true
                        return ''
                    } else {
                        gameLaunchUrl += "?gamelaunchData%5Bnomination%5D=" + this.superomaticNomination
                        return gameLaunchUrl
                    }
                }
            },
            superoNominationSelected(value) {
                this.superomaticNomination = value
                this.loaded = true
            },
            containerSize() {
                try {
                    let availWidth = document.querySelector('.game-cross-block').offsetWidth
                    return {
                        width: availWidth,
                        height: availWidth / 1.8
                    }
                } catch (err) {
                    console.log(err.message)
                }
            },
            screenToggle() {
                if (document.fullscreenElement) {
                    this.closeFullscreen();
                } else {
                    this.fullscreen()
                }
            },
            fullscreen() {
                let framesDiv = document.querySelector("#game-response-container");
                if (document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled) {
                    if (framesDiv.requestFullscreen) {
                        framesDiv.requestFullscreen();
                    } else if (framesDiv.webkitRequestFullscreen) {
                        framesDiv.webkitRequestFullscreen();
                    } else if (framesDiv.mozRequestFullScreen) {
                        framesDiv.mozRequestFullScreen();
                    } else if (framesDiv.msRequestFullscreen) {
                        framesDiv.msRequestFullscreen();
                    }
                    if (framesDiv.webkitExitFullscreen) {
                        framesDiv.webkitExitFullscreen()
                    }
                }
            },
            closeFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            },
            gotoGameView(game) {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: game.name,
                        gameId: game.id,
                        game: game,
                        provider: game.system,
                        isLive: game.isLive
                    }
                })
            },
            setIframeSizes() {
                let container = document.querySelector('.game-response-container')
                if (!container) {
                    return
                }
                // console.log(window.innerWidth);
                // let mediaHeight = '';
                // if(window.innerWidth < 424){
                //     mediaHeight = container.offsetWidth
                // }else{
                    let mediaHeight = container.offsetWidth / 1.5
                // }
                let sizes = {
                    width: container.offsetWidth,
                    height: mediaHeight,
                }
                if(this.provider === "alexa") {
                    sizes = {
                        width: parseInt(container.offsetWidth),
                        height: parseInt(window.innerHeight),
                    }
                }
                let gameLaunchIframe = document.querySelector('.gameLaunchIframe')
                gameLaunchIframe.style.height = sizes.height + 'px'
                gameLaunchIframe.style.maxHeight = sizes.height + 'px'
                gameLaunchIframe.style.width = sizes.width + 'px'
                gameLaunchIframe.style.maxWidth = sizes.width + 'px'
                if (!this.isWheel) {
                    document.querySelector('.game-info-area').style.height = sizes.height + 'px'
                }
            },
            setOriantationOnChangeEvent() {
                window.addEventListener('resize', () => {
                    setTimeout(() => {
                        this.isLandscape = window.innerWidth > window.innerHeight
                        this.setIframeSizes()
                    }, 20)
                })
            },
            closeUrl() {
                let closeUrl = this.gameCloseUrl
                this.response = null
                this.axios.get(closeUrl)
                        .then(({data}) => {
                            this.response = data
                            if (this.response.status === "ok") {
                                setTimeout(() => {
                                    this.$store.dispatch('checkForAliveGame')
                                    return this.$router.push({ name: 'home' })
                                }, 1000)
                            }
                        }).catch(err => {
                    if (err) {
                        this.response = {status: 'error', msg: 'Ошибка при закрытии игры. Попробуйте снова'}
                        return this.$router.push({ name: 'home' })
                    }
                })
            }
        },
        mounted() {
            if (!this.userData) {
                this.$router.go(-1)
                this.$root.$emit('bv::show::modal', 'login-modal')
                return
            }
            this.setOriantationOnChangeEvent();
            this.setIframeSizes()
            if (document.querySelector('.gameLaunchIframe')) {
                document.querySelector('.gameLaunchIframe').onload = () => {
                    this.loaded = true
                }
            }

            document.querySelector("#minimize").style.display = 'none'
            document.querySelector("#close-btn-full").style.display = 'none'
            setTimeout(() => {
                secondScrollTo('iframe')
            }, 600)

            let fullScreenBtn = document.querySelector('#fullScreenBtn')

            document.addEventListener('webkitfullscreenchange', this.fullscreenChange)
            document.addEventListener('mozfullscreenchange', this.fullscreenChange)
            document.addEventListener('fullscreenchange', this.fullscreenChange)
            document.addEventListener('MSFullscreenChange', this.fullscreenChange)
            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    document.querySelector("#minimize").style.display = 'block'
                    document.querySelector("#close-btn-full").style.display = 'block'
                } else {
                    document.querySelector("#minimize").style.display = 'none'
                    document.querySelector("#close-btn-full").style.display = 'none'
                }
            })
            document.getElementById('game-response-container').addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize text-white"></i>'
                } else {
                    let frame = document.querySelector('#game-response-container');

                    frame.style.height = this.containerSize().height + 'px'
                    fullScreenBtn.innerHTML = '<i class="fa fa-arrows-alt text-white"></i>'
                }
            });
        }
    }
</script>

<style>
    .enableFullScreen {
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .game-page-tab .tab-pane {
        padding: 0 !important;
    }

    .game-page-tab .nav-tabs {
        border: none !important;
        justify-content: center !important;
    }

    .game-page-tab .nav-link {
        border: none !important;
        padding: 0 !important;
        outline: none !important;
        color: white !important;
    }

    .game-page-tab .nav-link.active > .tab-title {
        background-color: #002262;
    }

    .game-page-tab .nav-item {
        width: 50%;
    }
    .game-info-area>div:first-child{
        position: absolute;
        z-index: 2;
        width: 100%;
    }
    .game-info-area .tab-content{
        margin-top: 55px;
    }
</style>

<style scoped>
    #minimize,
    #close-btn-full {
        box-shadow: none !important;
    }

    .game-title {
        word-break: break-all;
    }

    .buttons-container {
        height: 35px;
    }

    .d-table-cell {
        vertical-align: middle;
    }

    .tab-title {
        background-color: #000c1e;
    }

    .close-btn {
        background-image: linear-gradient(to right, #e5001b, #fb3721);
        box-shadow: none !important;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        top: -4px;
        right: -15px;
        z-index: 2;
    }

    .tab-title-area {
        background-color: #002262;
    }

    .game-info-area .tab-content {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;
    }

    .game-info-area::-webkit-scrollbar {
        background-color: #031135;
        width: 5px;
    }

    .game-info-area::-webkit-scrollbar-thumb {
        background-color: #0344bd;
        border-radius: 7px;
    }

    .light-bg {
        background-color: #002262;
    }

    .game {
        height: 100px;
    }

    .game-img {
        border-radius: 7px;
        object-fit: contain;
    }

    .game-info-area {
        background-color: #001844;
        overflow-x: hidden;
    }

    .game-load-block {
        background-image: url("../../../public/images/bg.png");
        min-height: fit-content !important;
        background-repeat: no-repeat;
        height: fit-content;
    }

    .game-load-block .game-load-alert {
        z-index: 2;
        top: 150px;
        left: 50%;
        width: 70%;
        margin-left: -35%;
        min-height: 200px;
    }

    .game-load-block .game-load-alert .alert {
        min-height: 200px;
        align-items: center !important;
        justify-content: center;
        display: flex !important;
        padding: 0 !important;
    }

    #app.mobile .page-content.main-block {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .resp-loader.loading {
        max-width: 300px;
        max-height: 300px;
        top: unset;
        left: unset;
    }
</style>
