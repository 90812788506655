<template>
    <b-modal id="HasAliveGame" size="md"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             :hide-header-close="true"
             v-model="show"
    >
        <h3 class="text-white text-center"> У вас есть активная игра! </h3>
        <b-row class="w-100 mx-auto">
            <alert :message="response"></alert>
        </b-row>
        <div v-for="(aliveGame, index) in aliveGames" :key="index">
            <div class="row mx-0">
                <div class="col-12 text-center" :class="[aliveGames.length > 1 ? 'col-md-4' : '']">
                    <img :src="$config.baseUrl + '/' + aliveGame.game.preview" class="game-image w-100"
                         alt="alive game">
                </div>
                <div class="col-12 my-3" :class="[aliveGames.length > 1 ? 'col-md-8' : '']">
                    <div class="row mt-3 mx-0">
                        <div class="col-7 text-center px-0 mx-auto">
                            <button class="btn red-btn w-100" @click.prevent="restore(aliveGame.game)">
                                Продолжить
                            </button>
                        </div>
                        <div class="col-6 text-center px-0 mx-auto">
                            <a class="btn text-white mt-2 close-btn" @click.prevent="closeUrl(aliveGame.game)"
                               :disabled="isClosing"> Закрыть </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        name: "AliveGamePopup",
        data() {
            return {
                isClosing: false,
                response: null,
                show: false
            }
        },
        components: {
            Alert
        },
        computed: {
            aliveGames() {
                return this.$store.getters['aliveGames']
            }
        },
        methods: {
            restore(game) {
                if (typeof this.aliveGames === 'object' && Object.keys(this.aliveGames).length > 1) {
                    this.response = {status: 'error', msg: 'Чтобы продолжить игру закройте предворитель оькрытые игры.'}
                } else {
                    this.$bvModal.hide('HasAliveGame')
                    this.$router.push({
                        name: 'TheGame',
                        // name: game.lobbyData ? 'TheLiveGame' : 'TheGame',
                        params: {
                            gameName: game.game_name,
                            gameId: game.game,
                            game: game,
                            provider: game.type,
                            restoring: true
                        }
                    })
                }
            },
            closeUrl(game) {
                let closeUrl = this.$_config.closeGameUrl + '/' + game.type
                this.isClosing = true
                this.response = null
                this.axios.get(closeUrl)
                    .then(({data}) => {
                        this.response = data
                        if (this.response.status === "ok") {
                            setTimeout(() => {
                                this.$store.dispatch('checkForAliveGame')
                                this.isClosing = false
                                this.$root.$emit('bv::hide::modal', 'HasAliveGame')
                            }, 1000)
                        }
                    }).catch(err => {
                    if (err) {
                        this.isClosing = false
                        this.response = {status: 'error', msg: 'Ошибка при закрытии игры. Попробуйте снова'}
                    }
                })
            }
        },
        watch: {
            aliveGames() {
                if (this.aliveGames && this.aliveGames.length) {
                    this.$root.$emit('bv::show::modal', 'HasAliveGame')
                }
            }
        },
        mounted() {
            if (this.aliveGames && this.aliveGames.length) {
                this.$root.$emit('bv::show::modal', 'HasAliveGame')
            }
        }
    }
</script>

<style scoped>
    .game-image {
        background-color: #130A1B;
        object-fit: contain;
        max-width: 200px;
        height: 150px;
    }

    .close-btn {
        box-shadow: none !important;
    }
</style>

