<template>
    <div class="withdraw-container main-bg-light row w-100 mx-0 py-5">
        <div class="col-11 mx-auto">
      <b-tabs class="pb-3 px-0 mt-5 text-center home-pills" pills>
        <b-tab class="col-12">
            <template slot="title" class="mx-0">
            <div class=" py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab  w-100" name="withdraw">
                    Выплата
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
                <div class="col-12 px-0 mx-auto pt-1 pt-md-5 pt-lg-3 mt-5 pt-xl-0">
                    <h2 class="text-white mb-3">Вывести выигрыш </h2>
                    <h6 class="text-white mb-4">Пополни баланс и выиграй </h6>

                    <div class="row mx-0">
                        <div class="col-12 pl-0 pr-0 pr-lg-2" :class="[userData ? 'col-lg-6' : 'col-lg-8 mx-auto']">
                            <div class="withdraw-methods-container main-bg p-3 p-xl-5">
                                <h6 class="text-uppercase text-white">Выберите Способ вывода</h6>
                                <div class="row mx-0" v-if="withdrawMethods">
                                    <div class="col-4 col-lg-3 col-xl-2 mx-auto mt-3 px-0"
                                        v-for="(method, index) in withdrawMethods"
                                        :key="index"
                                        v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                        <div class="withdraw-method text-center"
                                            :class="[method.name == selectedMethod.name ? 'selected' : '']">
                                            <img :src="$config.baseUrl + method.img" :data-src="$config.baseUrl + method.img"
                                                :alt="method.name" v-on:click="changeMethod(method)"
                                                class="withdraw-method-image cursor-pointer mw-100 mh-100">
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row justify-content-center my-3 w-100">
                                    <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pr-0 pl-0 pl-lg-2 pt-3 pt-lg-0" v-if="userData">
                            <div class="withdraw-methods-container main-bg p-3 p-xl-5">
                                <h6 class="text-white">Вывод на {{selectedMethod.name}}</h6>
                                <span class="fs-14 text-white">Минимальная сумма выплаты {{selectedMethod.min_amount}} руб.</span><br>
                                <span class="fs-14 text-white">Максимальная сумма выплаты {{selectedMethod.max_amount}} руб. за один перевод.</span><br>
                                <span class="fs-14 text-white" v-if="selectedMethod.time">
                                    до {{ selectTime(selectedMethod.time) }}
                                </span><br>
                                <!-- <span class="fs-14 text-white">Максимальный вывод в течение суток 50.000 рублей, остаточный выигрыш можете вывести на следующий день.</span> -->
                                <span class="fs-14 text-white">{{selectedMethod.add_info}}</span>

                                <b-form class="withdraw-form mt-3">
                                    <div class="row mx-0">
                                        <div class="clearfix"></div>
                                        <Alert :message="formResponse"></Alert>
                                    </div>
                                    <div class="row mx-0">
                                        <div class="col-sm-10 col-md-8 col-lg-12 col-xl-6 mx-auto px-0 pr-xl-1">
                                            <input class="form-control" :min="selectedMethod.min_amount" max="selectedMethod.max_amount" id="withdraw-amount" required
                                                v-model="payload.amount" aria-label="withdraw-amount" autocomplete="off"
                                                placeholder="Сумма в рублях *" type="number" v-on:keyup="keyUp">
                                        <span  class="hint-amount" v-if="withdrawinfo" style="margin-top: 10px; color: red">{{ withdrawinfotext }}</span>
                                        </div>
                                        <div class="col-sm-10 col-md-8 col-lg-12 col-xl-6 mx-auto px-0 pl-xl-1 mt-3 mt-xl-0">
                                            <input v-mask="getSelectedMethodMask" class="form-control" aria-label="recipient"
                                                v-model="payload.recipient" type="text"
                                                :placeholder=selectedMethod.requisites_title>
                                        </div>
                                        <div class="col-sm-12 mx-auto px-0 pl-xl-1 mt-3" v-if="selectedMethod.additional_info.length">
                                            <div class="input-group">
                                                <select class="form-control  form-select" v-model="payload.bank">
                                                    <option :value="null" disabled selected>Выбери банк</option>
                                                <option v-for="(method, index) in selectedMethod.additional_info"
                                                :key="index"
                                                :value="method.code"
                                                >
                                                {{ method.name }}
                                                </option>
                                            </select>   
                                            </div>
                                        </div>
                                        <div class="col-12 tip" v-if="tipData && tipData.use && tipData.use === true ">
                                            <div class="tip-block">
                                                <div class="text-block">
                                                    <p>Хочу оставить на чай</p>
                                                </div>
                                                <div class="tip-type-block">
                                                    <div class="fin" v-for="(data, index) in tipTypesData" :key="index">
                                                        <label for="finButton">
                                                        <img :src="data.src" class="fin-icon tip-type"
                                                        :class="{ 'active-type': index === activeType }"
                                                        @click="tipTypeAction(data.value,index)">
                                                        </label>
                                                        <div class="text-center">
                                                            <span>{{data.label}}</span>
                                                        </div>
                                                        <div class="check-block" >
                                                            <svg class="checkmark" width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ 'active-check': index === activeType }">
                                                                <path class="checkmark__check" d="M24 51.6739C25.8629 51.6739 35.5417 62.1638 40.8583 68.0867C42.7759 70.223 46.0818 70.3683 48.1455 68.3727C57.6301 59.2012 81.8373 36 84 36" stroke="#098a1b" stroke-width="10" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tip-amount-block">
                                                    <div class="smaylik-amount-block">
                                                        <div class="amout-smayl" v-for="(data, index) in tipAmountData" :key="index">
                                                            <label for="amountBtn10">
                                                            <img :src="data.src" class="amount-icon"
                                                            @click="tipAmountAction(tipData.amounts[index],index)"
                                                            :class="{ 'active-amount': index === activeAmount }">
                                                            </label>
                                                        </div> 
                                                    </div>
                                                    <div class="samyl-text-block">
                                                        <span>Выберите сумму для чаевых от {{ tipData.amounts[0] }}  руб․</span>
                                                    </div>
                                                    <div>
                                                        <input  v-model="tipAmount"
                                                                v-on:keyup="tipAmountkeyUp"
                                                                placeholder="Сумма на чай"
                                                                class="form-control input-md rect tip-amount-val">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    <!-- <div class="col-sm-12 mx-auto px-0 pl-xl-1 mt-3" v-if="selectedMethod.type == 'auris_ALL'">
                                        <input class="form-control"
                                            v-model="payload.bank" type="text"
                                            placeholder='Название банка *'>
                                    </div> -->
                                        <div class="col-sm-10 col-md-8 col-lg-12 mx-auto mt-4 px-0">
                                            <button class="btn red-btn h-100 w-100" type="submit" :disabled="isSubmitting"
                                                    @click.stop.prevent="openModal()">
                                                Вывести деньги
                                            </button>
                                        </div>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
        </b-tab>
        <b-tab class="col-12" v-if="userData">
            <template slot="title" class="mx-0">
            <div class="tab-title py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab  w-100" name="history">
                    История
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
            <div class="history" ref="history">
                <div class="history-inner">
                   <div>
                    <b-tabs class="pb-3 mt-3 px-0 text-center tab-li" pills>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 col-12 text-center category-item px-0">
                                <button class="btn  pay-tab  w-100" name="history_withdraw">
                                    Депозит
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="depositHistory ? depositHistory : null "/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 col-12 text-center category-item px-0">
                                <button class="btn  pay-tab  w-100" name="history_withdraw">
                                    Выплата
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="withdrawHistory ? withdrawHistory : null"/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                    </b-tabs> 
                   </div>
                </div>
            </div>
            </div>
        </b-tab>
      </b-tabs> 
    </div>
        <b-modal id="confirmWithdrawModal"
                 class="withdraw-modal" size="md"
                 :hide-footer="true" :hide-header="true"
                 v-model="confirmWithdrawModal"
        >
            <div class="col-12 px-0 text-center my-4">
                <span class="text-white">Подтвердите вывод на {{payload.recipient}} </span>
                <h2 class="text-white">На сумму {{payload.amount}} Руб.</h2>
                <div class="row mx-0 my-2">
                    <Alert :message="popupMessages"></Alert>
                </div>
                <div class="form-group row mx-0">
                    <div class="col-6 px-1 px-md-3 px-lg-4">
                        <button class="btn gray-btn px-5 float-right"
                                @click.prevent="$root.$emit('bv::hide::modal','confirmWithdrawModal')">
                            Отмена
                        </button>
                    </div>
                    <div class="col-6 px-1 px-md-3 px-lg-4">
                        <button class="btn red-btn px-5 float-left" type="submit" @click.prevent="makeWithdraw()">
                            Вывести
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Alert from '@/components/Alert'
    import History from './History.vue'

    export default {
        name: 'Withdraw',
        data() {
            return {
                payload: {
                    recipient: '',
                    amount: '',
                    bank: null
                },
                tipType:'',
                tipAmount:'',
                tipTypesData:[
                    {src:require('@/../public/images/icons/tips/fin1.png'), value:1, label: "Фин.Отдел"},
                    {src:require('@/../public/images/icons/tips/meneg1.png'), value:2, label: "Менеджер"},
                ],
                tipAmountData:[
                    {src:require('@/../public/images/icons/tips/10.png')},
                    {src:require('@/../public/images/icons/tips/50.png')},
                    {src:require('@/../public/images/icons/tips/100.png')},
                    {src:require('@/../public/images/icons/tips/no-tip.png')},
                ],
                activeAmount:'',
                activeType:'',
                selectedMethod: {
                    name: '',
                    mask: '',
                    min_amount: 10,
                    max_amount: 15000,
                    type: '',
                    time:'',
                    add_info: '',
                    additional_info:'',
                },
                isSubmitting: false,
                modalConfirmed: false,
                confirmWithdrawModal: false,
                formResponse: {},
                popupMessages: {},
                withdrawinfo: false,
                withdrawinfotext: ''
            }
        },
        components: {
            Alert,
            History
        },
        computed: {
            getSelectedMethodMask() {
                if(this.selectedMethod.mask && this.selectedMethod.mask != ''){
                    return typeof this.selectedMethod.mask === 'string' ? this.selectedMethod.mask : this.selectedMethod.mask.join(',').split(',');
                }
                return false;
            },
            withdrawMethods() {
                return this.$store.getters['getAvilWithdrawMethods']
            },
            withdrawHistory () {
                return this.$store.getters.getLastWithdrawHistory
            },
            depositHistory () {
            return this.$store.getters.getDepositHistory
            },
            userData() {
                return this.$store.getters['userData']
            },
            tipData() {
                return this.$store.getters.getTipData
            },
        },
        methods: {
            getImage(type) {
                return require('@/../public/images/payment/' + type + '.png')
            },
            tipAmountkeyUp(){
                this.isSubmitting = false
            },
            selectTime(time){
                if(time > 59){
                let hours = Math.floor(time / 60);
                let minutes = time % 60;
                let  formatTime = hours + " ч. ";
                if (minutes !== 0) {
                formatTime += minutes + " мин.";
                }
                return formatTime ;
                }else{
                return time + " мин.";
                }
            },
            changeMethod(method) {
                this.withdrawinfo = false
                if (!this.userData) {
                    this.selectedMethod.name = ''
                    this.selectedMethod.mask = ''
                    this.selectedMethod.type = ''
                    this.selectedMethod.add_info = ''
                    this.selectedMethod.min_amount = 10
                    this.selectedMethod.max_amount = 15000
                    this.$root.$emit('bv::show::modal', 'login-modal')
                    return
                }
                this.formResponse = '';
                this.payload.bank = null;
                this.payload.recipient = '';
                this.isSubmitting = true
                this.tipType = '';
                this.tipAmount = '';
                this.activeAmount = '',
                this.activeType = '',
                this.selectedMethod = method

                let elem = document.querySelector(".withdraw-form")
                if (elem !== null) {
                    let position = elem.getBoundingClientRect().top + document.documentElement.scrollTop
                    position = parseInt(position)
                    window.scrollTo({
                        top: position,
                        behavior: "smooth"
                    });
                }
                setTimeout(() => {
                    let amountInput = document.getElementById("withdraw-amount")
                    if (amountInput) {
                        amountInput.focus();
                    }
                }, 500)
            },
            makeWithdraw() {
                if (!this.userData) {
                    return
                }
                this.formResponse = {status: 'loading'}
                this.popupMessages = {status: 'loading'}

                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/newWithdraw',
                    {
                        withdraw_amount: this.payload.amount,
                        withdraw_requisites: this.payload.recipient,
                        method: this.selectedMethod.type,
                        bank: this.payload.bank,
                        tipType:this.tipType,
                        tipAmount:this.tipAmount
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    if (data.status === 'ok') {
                        this.popupMessages = data
                        this.formResponse = {}
                        this.payload.amount = null
                        this.payload.recipient = null
                        this.payload.bank = ''
                        this.activeAmount = '',
                        this.activeType = '',
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
                        }, 4000)
                    } else {
                        this.confirmWithdrawModal = false
                        this.formResponse = data
                    }
                    this.isSubmitting = false
                }).catch(err => {
                    console.log(err)
                })
                return true
            },
            openModal() {
                this.formResponse = {}
                this.popupMessages = {}

                if (!this.userData) {
                    this.formResponse = {status: 'error', msg: "Unauthorized"}
                    setTimeout(() => {
                        this.$root.$emit('bv::show::modal', 'login-modal')
                    }, 500)
                    return false
                }

                if (!this.selectedMethod.name) {
                    this.formResponse = {status: 'error', msg: "Пожалуйста выберите платежную систему"}
                    return false
                }

                if (!this.payload.amount || this.payload.amount < this.selectedMethod.min_amount
                  || this.payload.amount > this.selectedMethod.max_amount
                ) {
                    this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
                    return false
                }

                if (!this.payload.recipient) {
                    this.formResponse = {status: 'error', msg: "Пожалуйста, заполните все поля"}
                    return false
                }

                if (this.selectedMethod.type == "auris_ALL") {
                  var regs = /^[A-Za-z0-9а-яА-Я]{3,30}$/
                  if(!regs.test(this.payload.bank)){
                    this.formResponse = {status: 'error', msg: 'Название банка - неправильный формат'}
                    return false
                  }
                }

                this.confirmWithdrawModal = true
            },
          keyUp(evt){
            this.withdrawinfo = false
            if(this.selectedMethod.type == "visacardpercent" && evt.target.value >= this.selectedMethod.min_amount){
              this.withdrawinfo = true
              this.withdrawinfotext = "Поступят "+(evt.target.value-50)+" руб, 50 руб комиссия"
            }
          },
          tipTypeAction(type,index) {
                this.tipType = type
                this.activeType = index
            },
        
            tipAmountAction(amount,index){
                this.isSubmitting = false
                this.tipAmount = amount
                this.activeAmount = index
            }
        },

        mounted(){
            this.$store.dispatch('getWithdrawHistory')
            this.$store.dispatch('getDepositHistory')
        }
    }
</script>

<style scoped>
    .withdraw-methods-container {
        border-radius: 5px;
    }

    .withdraw-method {
        border: 2px solid transparent;
    }

    .withdraw-method-image {
        height: 50px;
        padding: 10px;
        object-fit: contain;
    }
    .form-select{
        background-color: #07162e !important;
        border-radius: 20px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        color: #ffffff !important;
        border: none !important;
    }
    .form-select option{
     background: #07162e !important;
    }
    .selected {
        border: 2px solid #4c75a0;
    }
    .tip{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.tip-block{
    width: 300px;
}
.fin-icon,
.mange-icon{
    cursor: pointer;
    transition: fill 0.3s;
    border-radius: 50%;
    object-fit: contain;
}
.samyl-text-block{
    text-align: center;
    color: #ffffff;
}
.text-block{
    margin-top: 20px;
    font-size: 20px;
}
.mange span{
    margin-right: 20px;
}
.tip-type-block{
    height: 130px;
    display: flex;
    justify-content: space-around;
    color: #ffffff;
}
.tip-type{
  width: 70px;
  height: 70px;
  transition: width 0.5s, height 0.5s;
}
.active-type{
  width: 80px;
  height: 80px;
}
.smaylik-amount-block{
    height: 70px;
    display: flex;
    justify-content: space-between;
}
.text-block p{
    text-align: center;
    color: #ffffff;
}
.amount-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
.active-amount{
    width: 60px;
    height: 60px;
}

.tip-amount-block{
    margin-top: 30px;
}

.tip-amount-val{
    margin-top: 30px;
    margin-bottom: 30px;
}
.checkmark{
  display: none;
}
.active-check{
  display: block;
}
.check-block{
    display: flex;
    justify-content: center;
}
.checkmark__check {
    stroke-dasharray: 412;
    stroke-dashoffset: 412;
    animation: anim 1s ease forwards 0.3s;
}

@keyframes anim {
    to {
        stroke-dashoffset: 0;
    }
}
.pay-tab{
      color: #fff!important;
  }
  .pay-tab:hover{
    text-decoration: underline;
  }
  .nav-pills .nav-link.active .pay-tab {
    width: 25%;
    background-color: #e7031b;
      border-bottom: 2px solid #e7021e;
  }
  
  .pay-tab:active,
  .pay-tab:focus {
      outline: none; 
      box-shadow: none!important;
  }

  @media (max-width: 992px) {
      #paymentModal{
          padding-left: 0 !important;
      }
  }
  @media (max-width:402px) {
      .pay-tab{
        font-size: 13px;
      }
  }
  @media (max-width:338px) {
      .pay-tab{
        font-size: 11px;
      }
      .nav-pills{
      height: 43px;
  }
}
</style>
  
<style>
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
        color: aliceblue;
        background-color: unset!important;
    }
    .nav-item{
        width: 33%;
    }
    .nav-pills .nav-link {
        padding: 0.5rem;
    }
    .nav-pills{
        height: 47px;
    }
    @media (max-width:338px) {
        .nav-pills{
        height: 43px;
    }
    }
</style>


