<template>
    <b-modal :hide-footer="true"
             :hide-header="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             id="msgs-modal"
             v-model="show"
    >
        <div class="popup-body row mx-0 justify-content-center">
            <h3 class="text-white mb-3">У вас новое сообщение!</h3>

            <div class="row w-100 msg-item my-2 px-0 mx-0" v-for="(msg, idx) in unreadMessages" :key="idx">
                <div class="col-12">
                    <div class="row mx-0 message-text-container text-white" v-html="msg.message"></div>
                    <div class="row mx-0 mt-2 justify-content-end">
                        <button class="btn btn-sm markAsReadBtn text-orange" @click.prevent="markAsRead(msg.id)">
                            Отметить как прочитанное
                        </button>
                    </div>
                    <hr class="my-2">
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        computed: {
            unreadMessages() {
                return this.$store.getters['getUnreadMessages']
            },
            show() {
                return this.$store.getters['getUnreadMessages'].length ? true : false
            }
        },
        methods: {
            markAsRead(id) {
                this.axios.get(this.$_config.baseUrl + '/Api/MessageViewed/' + id)
                this.$store.dispatch('fetchMessages')
            }
        }
    }
</script>

<style scoped>
    .message-text-container {
        word-break: break-all;
    }

    hr {
        background-color: white;
    }

    .markAsReadBtn {
        right: 0;
        bottom: 0;
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffffff !important;
    }
</style>
