<template>
    <div class="footer row w-100 mx-0">
        <div class="footer-menu col-12 px-2 px-md-5 px-xl-2 mx-auto py-5 fs-14">
            <div class="row mx-0">
                <div class="col-12 col-xl-2 px-0 position-relative">
                    <router-link :to="{ name: 'home' }" exact>
                        <img :src="logo" alt="SegaSlot - Logo" class="footer-logo position-absolute img-center mw-100">
                    </router-link>
                </div>

                <div class="col-12 col-xl-6 px-0 mt-3 mt-xl-0 px-1 px-md-0">
                    <div class="row w-100 mx-0">
                        <div class="col-12 col-md-6 col-xl-6 px-0 mt-4 mt-xl-0">
                            <h6 class="text-white mb-4 text-sm-center text-md-left"> Контакты </h6>

                            <div class="row mx-0">
                                <div class="col-6 col-sm-4 col-md-6 pr-0 mx-auto">
                                    <img :src="socialIcons.telegram" alt="telegram">
                                    <a href="https://t.me/segaSlotcasinomanager" target="_blank" rel="noreferrer"
                                       class="social-link text-white ml-2"> tg-manager
                                    </a>
                                </div>
                              <div class="col-6 col-sm-4 col-md-6 pr-0 mx-auto">
                                <img :src="socialIcons.vk" alt="vk">
                                <a href="https://vk.com/id552171960" target="_blank" rel="noreferrer"
                                   class="social-link text-white ml-2"> vk-manager
                                </a>
                              </div>
                            </div>

                            <div class="row mx-0 my-3">

                                <div class="col-6 col-sm-4 col-md-6 px-0 mt-2">
                                    <a id="claim_here" class="btn red-btn px-4 "> Написать жалобу </a>
                                </div>
                            </div>

                            <!--<div class="row mx-0 justify-content-sm-center justify-content-md-start">-->
                                <!--<a id="claim_here" class="btn red-btn px-4 mt-2   "> Написать жалобу </a>-->
                            <!--</div>-->

                        </div>
                        <div class="col-12 col-md-6 col-xl-6 px-0 mt-4 mt-xl-0">
                            <h6 class="text-white mb-4 text-sm-center text-md-left"> Меню </h6>

                            <div class="row mx-0">
                                <div class="col-4 col-sm-3 col-md-4 mx-auto px-0">
                                    <ul class="list-unstyled">
                                        <li class="mb-2">
                                            <router-link :to="{ name: 'home' }" exact>
                                                <span class="footer-link text-white">Казино</span>
                                            </router-link>
                                        </li>
<!--                                        <li class="mb-2">-->
<!--                                            <router-link :to="{ name: 'home' }" exact>-->
<!--                                                <span class="footer-link text-white">Главная</span>-->
<!--                                            </router-link>-->
<!--                                        </li>-->
                                        <!--<li class="my-3">-->
                                            <!--<router-link :to="{ name: 'download' }" exact>-->
                                                <!--<span class="footer-link text-white">Скачать</span>-->
                                            <!--</router-link>-->
                                        <!--</li>-->
<!--                                        <li>-->
<!--                                            <router-link :to="{ name: 'LiveGames' }" exact>-->
<!--                                                <span class="footer-link text-white">Live Dealers</span>-->
<!--                                            </router-link>-->
<!--                                        </li>-->
                                        <li class="mb-2">
                                            <router-link :to="{ name: 'bonuses' }" exact>
                                                <span class="footer-link text-white">Бонусы</span>
                                            </router-link>
                                        </li>
                                        <li class="mb-2">
                                            <a href="https://old.segaslot.com">
                                                <span class="footer-link text-white">Старый сайт</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-4 col-sm-3 col-md-4 mx-auto px-0">
                                    <ul class="list-unstyled">
                                        <li class="mb-2">
                                            <router-link :to="{ name: 'deposit' }" exact>
                                                <span class="footer-link text-white">Депозит</span>
                                            </router-link>
                                        </li>
                                        <li class="mb-2">
                                            <router-link
                                                    :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                                    exact>
                                                <span class="footer-link text-white">Колесо</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-4 col-sm-3 col-md-4 mx-auto px-0">
                                    <ul class="list-unstyled">
                                        <li class="mb-2">
                                            <router-link :to="{ name: 'withdraw' }" exact>
                                                <span class="footer-link text-white">Вывод</span>
                                            </router-link>
                                        </li>
                                        <li class="mb-2">
                                            <router-link :to="{ name: 'terms' }" exact>
                                                <span class="footer-link text-white">Условия</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-10 col-md-12 col-xl-4 mx-auto px-0 mt-4 mt-xl-0 text-left text-sm-center text-md-left">
                    <h6 class="text-white"> Платёжные системы </h6>
                    <router-link :to="{ name: 'deposit' }" exact>
                        <img :src="paymentMethods" alt="SegaSlot - Payment Methods" class="mw-100">
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row w-100 mx-0 copyright-area justify-content-center fs-14">
            <span class="text-light-blue py-2">© 2010-{{getThisYear}} Segaslot.Все права защищены.</span>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'RwvFooter',
        data() {
            return {
                logo: require('@/../public/images/logos/logo.png'),
                socialIcons: {
                    email: require('@/../public/images/icons/social/email.png'),
                    skype: require('@/../public/images/icons/social/skype.png'),
                    vk: require('@/../public/images/icons/social/vk.png'),
                    telegram: require('@/../public/images/icons/social/telegram.png')
                },
                paymentMethods: require('@/../public/images/payment/payment-methods.png')
            }
        },
        computed : {
            getThisYear() {
                return new Date().getFullYear()
            },
        }
    }
</script>

<style scoped>
    .main-menu .nav-item{
        text-align: center;
    }

    .copyright-area {
        background-color: #00143a;
    }

    .footer-menu {
        background-color: #001743;
    }

    .footer-menu span {
        color: white;
    }

    .footer-logo {
        width: 170px;
    }

    .social-link:hover {
        transition: 1s;
        color: #f93e22 !important;
    }

    .footer-link:hover {
        transition: 1s;
        color: #4d76a1 !important;
    }


    .router-link-active .footer-link {
        color: #4d76a1 !important;
    }

    .img-center {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
</style>
