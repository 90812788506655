<template>
    <div class="games-container pt-0 pt-lg-3" v-if="selectedTab && games">
        <div class="row mx-0 justify-content-around providers-container mb-2 d-none d-xl-block"
             v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
            <div class="col-11 mx-auto px-0">
                <div class="row mx-0">
                    <div class="col">
                        <b-form class="search-form w-100 my-2" autocomplete="off">
                            <b-form-group class="mb-0">
                                <b-form-input v-model="gamesSearchText" placeholder="Поиск игр" name="search-input"
                                              aria-label="SegaSlot - Поиск игр 12"
                                              class="input-bg input-bg-search"></b-form-input>
                                <b-button class="removeButton position-absolute border-0" v-if="gamesSearchText"
                                          @click.stop.prevent="searchGamev2()">
                                    <i class="fa fa-close"></i>
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-11 px-0 mx-auto">
            <!--         categories for mobile and tablets-->
            <div class="row mx-0">
                <div class="col-2 px-0 d-none d-xl-block">
                    <div class="row mx-0 mb-3 py-2 game-categories-container text-white">
                        <div class="col-12 px-0 d-none d-xl-block py-2 category-item cursor-pointer"
                             v-for="(tab, idx, index) in games"
                             :id="idx"
                             :key="idx"
                             :class="[idx===currentTabIndex ? 'active' : '']"
                             @click.prevent="changeTab(idx)">
                            <div class="triangle position-absolute"></div>

                            <div class="row mx-0">
                                <div class="col-2 px-0">
                                    <img :src="getIconForTab(index)" :alt="tab.name" class="category-icon"><br>
                                </div>
                                <div class="col-10 px-0">
                                    <span class="">{{tab.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-5 winners">
                        <winner-list></winner-list>
                    </div>
                </div>

                <div class="col-12 col-xl-10 games px-0">
                    <!-- for mobile and tablets START-->
                    <div class="row d-md-none mx-0 px-3 text-white">
                        <div class="col-12 px-0">
                            <b-button v-b-toggle.category-collapse class="w-100 category-collapse mt-3">
                                <span class="text-white float-left ml-3">Категории</span>
                                <img :src="arrow" class="menu-arrow float-right mt-2" alt="SegaSlot">
                            </b-button>
                        </div>
                        <b-collapse id="category-collapse" ref="categoryCollapse">
                            <b-card class="categories-card">
                                <div class="row mx-0 mb-3 py-2 px-2 px-lg-0 game-categories-container-mobile text-white">
                                    <div class="col-12 col-sm-5 py-2 mx-auto category-item cursor-pointer"
                                    v-for="(tab, idx, index) in games"
                                        :id="idx"
                                        :key="idx"
                                        :class="[idx===currentTabIndex ? 'active' : '']"
                                        @click.prevent="changeTab(idx)">
                                        <div class="row">
                                            <div class="col-1 col-sm-2 px-0">
                                                <img :src="getIconForTab(index)" :alt="tab.name">
                                            </div>
                                            <div class="col-10 mx-auto px-0" v-b-toggle.category-collapse>
                                                <span>{{tab.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-card>
                        </b-collapse>
                    </div>
                    <div class="row d-xl-none mx-0 px-3 px-sm-3 text-white">
                        <b-form class="search-form w-100 mt-3 mb-2" autocomplete="off">
                            <b-form-group class="mb-0">
                                <b-form-input v-model="gamesSearchText" placeholder="Поиск игр" name="search-input"
                                              aria-label="SegaSlot - Поиск игр"
                                              class="input-bg input-bg-search"></b-form-input>
                                <b-button class="removeButton position-absolute border-0" v-if="gamesSearchText"
                                          @click.stop.prevent="searchGamev2()">
                                    <i class="fa fa-close"></i>
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>

                    <!--    categories for tablets -->
                    <div class="row mx-0 px-3 d-none d-md-flex d-xl-none mt-3">
                        <div class="col-2 px-0 py-3 category-item cursor-pointer float-left text-center h-100"
                             v-for="(tab, idx, index) in games" :key="'category_' + idx"
                             @click.prevent="changeTab(tab,index)"
                             :class="[(index > shownCategoriesCount ? 'hidden canBeHidden': ''), (!index ? 'active' : '')]">
                            <img :src="getIconForTab(index)" :alt="tab.name" height="20">
                            <span class="text-white ml-1 ml-lg-2 fs-14">{{tab.name}}</span>
                        </div>

                        <div class="col-2 px-0 py-3 cursor-pointer float-left text-center h-100 categories-expander"
                             v-if="Object.keys(games).length > shownCategoriesCount"
                             @click="showAllCategories">
                            <span class="text-white" v-if="isShown">Скрыть</span>
                            <span class="text-white" v-else>Показать все</span>
                        </div>
                    </div>

                    <!--  for mobile and tablets END-->

                    <b-card no-body class="col-12 px-0 mt-3 mt-xl-0">
                        <b-tabs card vertical
                                class="games-tabs row justify-content-between"
                                id="games-tabs"
                                v-model="currentTab"
                                nav-wrapper-class="w-100"
                                ref="gamesTabs">

                            <GameTabTemplate
                                    :tab="selectedTab"
                                    :providers="selectedProviders"
                                    :category="selectedTab.name"
                            ></GameTabTemplate>

                            <b-tab title-item-class="search-tab" v-if="gamesSearchText"
                                   class="col-12 px-0"
                                   v-lazy-container="{ selector: 'img', observer: true, loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                    <div class="row games-list-row no-gutters px-0" v-if="foundedGames.length"
                                        v-lazy-container="{ selector: 'img', observer: true, loading: require('@/../public/images/icons/game-images-loader.svg') }"
                                    >
                                        <div class="col-6 col-md-2 game-item" v-for="(game, i) in foundedGames"
                                            v-if="foundedGames.length && !gamesSearchText" :key="'founded'+i">
                                            <GameItemPreview :game="game"></GameItemPreview>
                                        </div>
                                    </div>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                    <div class="col-12 mx-auto d-xl-none px-3 py-2">
                        <winner-list></winner-list>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div v-else class="games-container row justify-content-center py-5 w-100 mx-0">
        <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
    </div>
</template>

<script>
    import GameItemPreview from '@/components/games/GameItemPreview'
    import GameTabTemplate from "./GameTabTemplate"
    import WinnerList from "../home/WinnerList";

    export default {
        data() {
            return {
                selectedProviders: [],
                selectedTab: null,
                searchTimeoutId: null,
                foundedGames: [],
                showTabsCount: 5,
                currentTab: 0,
                currentTabIndex: '',
                currentTabName: 'Популярные',
                gamesSearchText: '',
                companyName: '',
                isShown: false,
                logosCount: 17,
                shownCategoriesCount: 10,
                arrow: require('@/../public/images/icons/arrow.png'),
                tabIcons: [
                    require('@/../public/images/icons/category-icons/cards.png'),
                    require('@/../public/images/icons/category-icons/jackpot.png'),
                    require('@/../public/images/icons/category-icons/like.png'),
                    require('@/../public/images/icons/category-icons/new.png'),
                    require('@/../public/images/icons/category-icons/other.png'),
                    require('@/../public/images/icons/category-icons/roulette.png'),
                    require('@/../public/images/icons/category-icons/slot.png'),
                    require('@/../public/images/icons/category-icons/sport.png'),
                    require('@/../public/images/icons/category-icons/top.png')
                ],
            }
        },
        components: {
            GameItemPreview,
            GameTabTemplate,
            WinnerList
        },
      methods: {
            showAllCategories() {
                if (!this.isShown) {
                    document.querySelectorAll('.category-item.canBeHidden').forEach((category) => {
                        category.classList.remove('hidden')
                    })
                } else {
                    document.querySelectorAll('.category-item.canBeHidden').forEach((category) => {
                        category.classList.add('hidden')
                    })
                }
                this.isShown = !this.isShown
            },
            searchGamev2() {
                if (this.gamesSearchText === '') {
                    return true
                }
                const providers = this.selectedProviders
                const games = []
                const gameUniqueIds = []
                this.foundedGames = []
                const searchInGames = this.games[this.currentTabIndex].games
                for (const i in searchInGames) {
                    if (!searchInGames.hasOwnProperty(i)) {
                    continue
                    }
                    const cg = searchInGames[i]
                    const name = cg.name.toLowerCase()
                    const gameProvider = cg.provider

                    if (this.gamesSearchText === '') {
                    if (providers.length > 0) {
                        if (providers.indexOf(gameProvider) !== -1) {
                        if (!cg.hasOwnProperty('unique_id')) {
                            continue
                        }
                        if (gameUniqueIds.indexOf(cg.unique_id) === -1) {
                            games.push(cg)
                            gameUniqueIds.push(cg.unique_id)
                        }
                        }
                    }
                    } else {
                    if (name.indexOf(this.gamesSearchText.toLowerCase()) !== -1) {
                        if (providers.length > 0) {
                        if (providers.indexOf(gameProvider) !== -1) {
                            if (!cg.hasOwnProperty('unique_id')) {
                            continue
                            }
                            if (gameUniqueIds.indexOf(cg.unique_id) === -1) {
                            games.push(cg)
                            gameUniqueIds.push(cg.unique_id)
                            }
                        }
                        } else {
                        games.push(cg)
                        gameUniqueIds.push(cg.unique_id)
                        }
                    }
                    }
                }

                this.foundedGames = games
                let gamesFound = false
                if (games.length > 0) {
                    gamesFound = true
                }
                this.selectedTab = {
                    games: games,
                    gamesFound: gamesFound,
                    searchText: this.gamesSearchText,
                    name: this.games[this.currentTabIndex].name
                }
                return true
            },
            changeTab (tabId) {
                if (this.games[tabId].games.length == 0) {
                    this.$store.dispatch('fetchGamesByProvidersv2', tabId)
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } else {
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } 
            },
            setTimeout(callback, timeout) {
                setTimeout(callback, timeout)
            },
            getIconForTab(idx) {
                if (idx > this.tabIcons.length) {
                    idx %= this.tabIcons.length
                }
                return this.tabIcons[idx] ? this.tabIcons[idx] : this.tabIcons[0]
            },
        },
        computed: {
            providers() {
                return this.$store.getters['getGameProviders']
            },
            firstTab() {
                if (this.games) {
                    return this.games[(Object.keys(this.games)[0])]
                }
                return false
            },
          games () {
            const allGames = this.$store.getters.games
            let availableGames = null
            if (!allGames) {
              return null
            }
            availableGames = {}
            for (const tabKey in allGames) {
              const tabOptions = allGames[tabKey].opts

              if (
                  (tabOptions.hasOwnProperty('android') && !tabOptions.android && this.$_config.isAndroid()) ||
                  (tabOptions.hasOwnProperty('ios') && !tabOptions.ios && this.$_config.isIos()) ||
                  (tabOptions.hasOwnProperty('desktop') && !tabOptions.desktop && !this.$_config.environment.mobile)
              ) {
                continue
              }

              availableGames[tabKey] = {
                hasNewIcon: allGames[tabKey].hasNewIcon ? allGames[tabKey].hasNewIcon : false,
                icon: allGames[tabKey].icon,
                name: allGames[tabKey].name,
                games: [],
                auth: !!allGames[tabKey].hasOwnProperty('need_auth'),
                role: allGames[tabKey].role ? allGames[tabKey].role : null,
                openAsFirstTab: allGames[tabKey].openAsFirstTab ? allGames[tabKey].openAsFirstTab : false,
              }

              for (const i in allGames[tabKey].games) {
                if (!allGames[tabKey].games.hasOwnProperty(i)) {
                  continue
                }
                const game = allGames[tabKey].games[i]
                if (
                    (game.hasOwnProperty('android') && !game.android && this.$_config.isAndroid()) ||
                    (game.hasOwnProperty('ios') && !game.ios && this.$_config.isIos()) ||
                    (game.hasOwnProperty('desktop') && !game.desktop && !this.$_config.environment.mobile) ||
                    (game.picture === '')
                ) {
                  continue
                }

                availableGames[tabKey].games.push(game)
              }
            }
            return availableGames
          },
            isMobile() {
                return this.$_config.environment.mobile
            },
            tabsCount() {
                try {
                    return Object.keys(this.games).length
                } catch (e) {
                    return 0
                }
            }
        },
        watch: {
            gamesSearchText (newVal, oldValue) {
                if (newVal !== '') {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true
                    this.searchGamev2()
                    }, 800)
                } else if (oldValue !== '') {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true
                    this.searchGamev2()
                    }, 800)
                } 
            },
            games() {
                if (this.games) {
                    this.selectedTab = this.games[(Object.keys(this.games)[0])]
                }
            }
        },
        updated () {
            if (this.games && this.gamesSearchText === '') {
            if (typeof this.currentTabIndex === 'undefined' || this.currentTabIndex === '') {
                this.currentTabIndex = Object.keys(this.games)[0]
            }
              this.selectedTab = this.games[this.currentTabIndex]
            }
        },
        mounted() {
            this.$store.dispatch('fetchGames')
            this.$store.dispatch('getGameProviders')
            this.$store.dispatch('checkForAliveGame')
            if (this.games) {
              this.currentTabIndex = Object.keys(this.games)[0]
                this.selectedTab = this.games[this.currentTabIndex]
            }
        }
    }
</script>

<style scoped>
    .category-icon{
        max-height: 28px;
    }

    .categories-expander {
        background-color: #002a79;
        border: 1px solid #0344bd;
    }

    .hidden {
        display: none;
    }

    .all-mobile {
        position: absolute;
        left: 0;
        right: 0;
        top: 18px;
        margin: auto;
    }

    .game-categories-container-mobile {
        max-height: 400px;
        overflow-y: scroll;
    }

    .game-categories-container-mobile::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }

    .game-categories-container-mobile::-webkit-scrollbar-thumb {
        background-color: #0344bd;
        border-radius: 7px;
    }

    .menu-arrow {
        transition: 1s;
    }

    .collapsed .menu-arrow {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .input-bg-search {
        background-image: url("../../../public/images/icons/form/search.png");
        background-size: 30px;
        background-color: #002a79 !important;
    }

    .search-form {
        min-width: 250px;
    }

    .input-bg-search:valid {
        padding-left: 35px !important;
    }

    .providers-expander,
    .providers-all{
        background-color: #002a79;
        height: 70px;
        width: 60px;
    }

    .games-container {
        background-color: #002262;
    }

    .providers-container-mobile {
        background-color: #0344bd;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        height: 70px;
    }

    .providers-container {
        background-color: #0344bd;
    }

    .category-item {
        border-bottom: 1px solid #0344bd;
    }

    .category-item:hover,
    .category-item.active {
        border-bottom: 1px solid #e7051c;
    }

    .games .card {
        background: transparent;
        border: none;
    }

    .removeButton {
        top: 12px;
        color: #f72e20 !important;
        box-shadow: none !important;
        background-color: transparent !important;
        left: 25px;
        padding-left: 0;
    }

    .fa-close {
        font-size: 25px;
    }

    .provider-logo.active {
        background-image: linear-gradient(to left, #e5001b, #fb3721);
    }

    .provider-logo img {
        height: 70px;
        width: 100px;
        padding: 7px;
        object-fit: contain;
    }

    @media only screen and (max-width: 768px) {
        .removeButton {
            top: 85px;
        }
    }

    @media only screen and (max-width: 1199px) {
        .provider-logo {
            border: 1px solid #0344bd;
        }

        .providers-all {
            margin: 5px;
            height: 60px;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .category-item {
            border: 1px solid #0344bd;
        }

        .category-item:hover,
        .category-item.active {
            border: 1px solid #e7051c;
        }

        .removeButton {
            top: 20px;
        }
    }
</style>

<style>
    .providers-card .card-body,
    .categories-card .card-body {
        padding: 0;
        padding-top: 10px;
    }

    .category-collapse {
        background-color: #0344bd !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 20px !important;
        padding: 12px 15px !important;
    }

    .search-tab .nav-link {
        background: transparent !important;
        border: none !important;
    }

    .games-tabs .card-header {
        background: transparent !important;
        padding: 0;
    }
</style>
